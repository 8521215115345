<template>
  <v-card dense>
    <v-img :src="document.image" class="white--text align-end" height="200px">
      <div style="background:rgba(0,0,0, 0.4);">
        <!-- <v-btn icon style="position: absolute; top: 4px; right: 10px;">
          <v-icon color="blue">fa-info-circle</v-icon>
        </v-btn> -->

        <v-hover v-slot="{ hover }">
          <v-card-title :class="{ 'custom-anchor': hover }" class="text-truncate" style="max-width:260px; display:block; cursor: pointer;" @click="shipDetail">{{document.name}}</v-card-title>
        </v-hover>
        <v-hover v-slot="{ hover }">
          <v-card-subtitle :class="{ 'custom-anchor': hover }" style="cursor: pointer;" @click="manufacturerDetail">{{document.manufacturerName}}</v-card-subtitle>
        </v-hover>
        <v-btn v-if="!bulkActions" icon style="position: absolute; bottom: 36px; right: 10px;" v-long-press="300" @long-press-start="setBulkActions" @click="addShipToShoppingCard({shipId: document.shipId, name: document.name, quantity: 1})">
          <v-icon :color="shoppingCard.find((item) => item.shipId === document.shipId) ? 'green' : 'white'">fas fa-plus-circle</v-icon>
        </v-btn>
        <v-btn v-if="bulkActions" icon style="position: absolute; bottom: 36px; right: 10px;" @click="addShipToBulkList(document.shipId)">
          <v-icon :color="bulkList.includes(document.shipId) ? 'green' : 'white'">{{bulkList.includes(document.shipId) ? 'far fa-check-circle' : 'far fa-circle'}}</v-icon>
        </v-btn>
      </div>
      <template v-slot:placeholder>
        <v-row
          class="fill-height mb-6"
          align="center"
          justify="center"
        >
          <v-progress-circular
            indeterminate
            color="blue"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>
  </v-card>
</template>

<script>

  import LongPress from 'vue-directive-long-press'

  export default {
    name: 'NavItem',

    directives: {
      'long-press': LongPress
    },

    props: {
      document: {
        type: Object,
        default: () => {
          return [];
        }
      },
      shoppingCard: {
        type: Array,
        default: () => {
          return [];
        }
      },
      addShipToShoppingCard: {
        type: Function,
        default: () => {}
      },
      bulkList: {
        type: Array,
        default: () => {
          return [];
        }
      },
      addShipToBulkList: {
        type: Function,
        default: () => {}
      },
      setBulkActions: {
        type: Function,
        default: () => {}
      },
      bulkActions: Boolean
    },

    data: () => ({
      shipMeta: [],
      shipOwnerName: '',
      showBulkAction: false
    }),

    watch: {
      shoppingCard(shoppingCard) {
        if (!shoppingCard.length) {
          this.showBulkAction = false;
        }
      },
      bulkList(bulkList) {
        if (!bulkList.length) {
          this.showBulkAction = false;
        }
        this.hasShipInCard = bulkList.find((item) => item.shipId === document.shipId);
      }
    },

    methods: {
      toggleShipMeta(shipId) {
        const shipIndex = this.shipMeta.indexOf(shipId);
        if (shipIndex === -1) {
          this.shipMeta.push(shipId);
          return;
        }
        this.shipMeta.splice(shipIndex, 1);
      },
      shipDetail() {
        this.$router.push({name: 'ShipDetail', query: {shipId: this.document.shipId}});
      },
      manufacturerDetail() {
        this.$router.push({name: 'ManufacturerDetail', query: {manufacturerId: this.document.manufacturerId}});
      }
    }
  };
</script>

<style lang="scss" scoped>
.custom-expand {
  z-index: 100;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  background: white;
  height: 100%;
  max-height: 204px;
}

.custom-anchor {
  color: skyblue;
  text-decoration: underline;
}
</style>
