<template>
  <div>
    <topbar v-show="!bulkList.length" drawer :title="navbarTitle" :shopping-card="shoppingCard" :open-shopping-card="() => shoppingCardDialog = true" :open-ships-import-dialog="() => shipsImportDialog = true" :ship-list="true"></topbar>

    <v-app-bar v-show="bulkList.length" ref="topbar" class="topbar">
      <v-btn icon @click="bulkList = []">
        <v-icon>fa-times</v-icon>
      </v-btn>
      <v-toolbar-title class="title">{{bulkList.length}} Ausgewählt</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="bulkList.length" class="mr-2" icon @click="shipUpdatePropsDialog = true">
        <v-icon>fas fa-edit</v-icon>
      </v-btn>
    </v-app-bar>

    <v-container v-if="!loading">
      <v-container>
        <v-text-field v-model="shipFilter.shipName" outlined clearable dense hide-details label="Avenger, Carrack, Cutlass">
        <v-btn icon slot="prepend" small @click="descending = !descending">
          <v-icon small>{{descending ? 'fas fa-chevron-down' : 'fas fa-chevron-up'}}</v-icon>
        </v-btn>
        <v-icon slot="prepend-inner" style="padding-top: 3px;" small>fa-search</v-icon>
        <v-icon slot="append-outer" :color="filterIconColor" @click="filterDrawer = !filterDrawer">fa-filter</v-icon>
        </v-text-field>
      </v-container>
      <v-container v-if="filteredShipsWithPages.length" fluid>
        <v-row dense>
        <v-col v-for="(ship, index) in filteredShipsWithPages" :key="index" cols="12" sm="6" md="6" lg="3" xl="2">
          <ship-list-item :document="ship" :shopping-card="shoppingCard" :add-ship-to-shopping-card="addShipToShoppingCard" :bulk-actions="bulkActions" :set-bulk-actions="setBulkActions" :bulk-list="bulkList" :add-ship-to-bulk-list="addShipToBulkList" />
        </v-col>
        </v-row>
      </v-container>
      <v-container v-if="!filteredShipsWithPages.length" fluid>
        <div class="d-flex justify-center mt-8">
          <div>No Ship Found</div>
        </div>
      </v-container>
      <v-pagination v-if="pages > 0" v-model="page" class="my-4" :length="pages"></v-pagination>
    </v-container>

    <v-navigation-drawer v-model="filterDrawer" disable-resize-watcher app right :width="drawerWidth">
      <v-toolbar class="custom-toolbar" elevation="0">
        <div class="d-flex pt-1">
          <h3 style="margin: 0;">Ship filter</h3>
        </div>
        <div style="margin-left: auto; display: flex; pt-1">
          <v-btn icon small @click="filterDrawer = false"><v-icon small>fas fa-times</v-icon></v-btn>
        </div>
      </v-toolbar>
      <v-container>
        <v-container>
          <v-select
            v-model="shipFilter.shipIds"
            :items="filteredShipIds"
            label="Ship name"
            class="custom-input-background mb-4"
            item-text="name"
            item-value="shipId"
            outlined
            multiple
            dense
            hide-details
          >
            <template v-slot:prepend-item @mousedown.stop @click.stop>
              <v-container>
                <v-text-field v-model="shipName" hide-details dense label="Avenger, Carrack, Cutlass"></v-text-field>
              </v-container>
            </template>
          </v-select>
          <v-select
            v-model="shipFilter.manufacturerIds"
            :items="filteredManufacturers"
            label="Manufacturers"
            class="custom-input-background mb-4"
            item-text="name"
            item-value="manufacturerId"
            outlined
            multiple
            dense
            hide-details
          >
            <template v-slot:prepend-item @mousedown.stop @click.stop>
              <v-container>
                <v-text-field v-model="manufacturerName" hide-details dense label="Anvil, MISC, Origin"></v-text-field>
              </v-container>
            </template>
          </v-select>
          <v-select
            v-model="shipFilter.size"
            :items="shipSizes"
            label="Size"
            class="custom-input-background mb-4"
            item-text="name"
            item-value="name"
            outlined
            multiple
            dense
            hide-details
          >
          </v-select>
        </v-container>
        <div class="d-flex justify-center">
          <v-btn rounded depressed small color="primary" @click="resetFilter">Reset filter</v-btn>
        </div>
      </v-container>
    </v-navigation-drawer>

    <v-dialog v-model="shoppingCardDialog" persistentmax-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Ships</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row v-for="(ship, index) in shoppingCard" :key="index">
              <v-col cols="6" sm="8">
                <v-text-field v-model="ship.name" readonly label="Ship name"></v-text-field>
              </v-col>
              <v-col cols="4" sm="2">
                <v-select
                  v-model="ship.quantity"
                  :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                  label="Anzahl"
                  hide-details
                >
                </v-select>
              </v-col>
              <v-col cols="2">
                <v-btn icon style="margin-top: 12px;" @click="removeShipFromShoppingCard(ship.shipId)">
                  <v-icon color="red">far fa-trash-alt</v-icon>
                </v-btn>
              </v-col>

            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="shoppingCardDialog = false">Close</v-btn>
          <v-btn color="blue darken-1" text @click="() => {shoppingCardDialog = false; shipPropsDialog = true}">Continue</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="shipPropsDialog" persistentmax-width="600px">
      <v-card width="800">
        <v-card-title>
          <span class="text-h5">Edit ship props</span>
        </v-card-title>
        <v-card-text>
          <span>After you klicked "{{newShip.data.length > 1 ? 'Add ships' : 'Add ship'}}" down below, you will find your {{newShip.data.length > 1 ? 'ships' : 'Add ship'}} in your hangar (if it was successful)</span>
        </v-card-text>
        <v-select
          v-model="newShip.hangarShipId"
          :items="newShip.data"
          label="Select ship"
          class="custom-input-background mb-4"
          style="padding: 16px 32px 0px 32px;"
          item-text="name"
          item-value="hangarShipId"
          outlined
          dense
          hide-details
        >
        </v-select>
        <v-card-text>
          <v-container v-if="newShip.data.length">
            <v-row dense>
              <v-col cols="12" sm="9">
                <v-text-field v-model="newShip.data[newShip.index].shipName" label="Ship name" hint="Emty field = rsi standard name"></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field v-model="newShip.data[newShip.index].price" label="Ship price" prefix="$" hint="Emty field = rsi standard price"></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-text-field v-model="newShip.data[newShip.index].images.small" label="Ship preview image" hint="Emty field = rsi standard image"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="newShip.data[newShip.index].images.big" label="Ship image" hint="Emty field = rsi standard image"></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="4">
                <v-checkbox v-model="newShip.data[newShip.index].lti" label="LTI"></v-checkbox>
              </v-col>
              <v-col cols="12" sm="4">
                <v-checkbox v-model="newShip.data[newShip.index].warbond" label="Warbond"></v-checkbox>
              </v-col>
              <v-col cols="12" sm="4">
                <v-checkbox v-model="newShip.data[newShip.index].flagship" label="Flagship"></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="shipPropsDialog = false">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="addShipsToHangar">{{newShip.data.length > 1 ? 'Add ships' : 'Add ship'}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="shipsImportDialog" persistentmax-width="600px">
      <v-card width="800">
        <v-card-title>
          <span class="text-h5">Import your ships</span>
        </v-card-title>
        <v-card-text>
          <span>Du kannst mit diesem Browser Addon https://github.com/dolkensp/HangarXPLOR deine Schiffe importieren, einfach den json inhalt in das Feld kopieren und auf Import klicken,
            es kann vorkommen das einige Schiffe mit der RSI Datenbank nicht abgeglichen werden können, diese musst du dann manuell hinzugefügen.
          </span>
        </v-card-text>
        <v-card-text>
          <v-container>
            <v-textarea
              v-model="importData"
              background-color="grey lighten-2"
              color="cyan"
              label="JSON DATA"
            ></v-textarea>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="shipsImportDialog = false">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="importShips">Import ships</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="shipUpdatePropsDialog" persistentmax-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Add props to ship</span>
        </v-card-title>
        <v-card-text>
          <span>After you klicked "{{editShip.data.length > 1 ? 'Update ships' : 'Update ship'}}" down below, your {{editShip.data.length > 1 ? 'ships' : 'ship'}} data will be updated at the next planned (or manual) ship sync with rsi</span>
        </v-card-text>
        <v-select
          v-if="editShip.data.length"
          v-model="editShip.shipId"
          :items="editShip.data"
          label="Select ship"
          class="custom-input-background mb-4"
          style="padding: 16px 32px 0px 32px;"
          item-text="displayName"
          item-value="shipId"
          outlined
          dense
          hide-details
        >
        </v-select>
        <v-card-text>
          <v-container v-if="shipPropsDialogLoading" class="d-flex justify-center">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-container>
          <v-container v-if="!shipPropsDialogLoading && editShip.data.length">
            <v-row dense>
              <v-col cols="12" sm="9">
                <v-text-field v-model="editShip.data[editShip.index].name" label="Ship name" hint="Emty field = rsi standard name"></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field v-model="editShip.data[editShip.index].price" label="Ship price" prefix="$" hint="Emty field = rsi standard name"></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-textarea v-model="editShip.data[editShip.index].description" label="Ship description" hint="Emty field = rsi standard name"></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="editShip.data[editShip.index].cargocapacity" label="Ship cargocapacity" hint="Emty field = rsi standard name"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="editShip.data[editShip.index].url" label="Ship url" hint="Link to rsi or other websites"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="resetShipData">Reset Data</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="shipUpdatePropsDialog = false">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="updateShips">{{editShip.data.length > 1 ? 'Update ships' : 'Update ship'}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
  import Topbar from '../components/Topbar';
  import ShipListItem from '../components/ShipListItem.vue';
  import { nanoid } from 'nanoid';

  export default {
    name: 'Ships',

    components: {
      Topbar,
      ShipListItem
    },
    data() {
      return {
        navbarTitle: 'Ships',
        page: 1,
        pages: 0,
        maxVisibleItems: 16,
        filterDrawer: false,
        shipName: '',
        filteredShipIds: [],
        manufacturerName: '',
        filteredManufacturers: [],
        shipSizes: [],
        filteredShips: [],
        filteredShipsWithPages: [],
        filterIconColor: 'gray',
        shipPropsDialog: false,
        shipUpdatePropsDialog: false,
        shipsImportDialog: false,
        importData: '',
        shipFilter: {
          shipName: '',
          shipIds: [],
          manufacturerIds: [],
          size: []
        },
        descending: false,
        addShipsToHangarLoading: false,
        shoppingCardDialog: false,
        shoppingCard: [],
        shippingCard: [],
        newShip: {
          index: 0,
          hangarShipId: [],
          data: []
        },
        bulkActions: false,
        bulkList: [],
        shipPropsDialogLoading: false,
        editShip: {
          index: 0,
          shipId: [],
          data: []
        }
      };
    },

    computed: {
      loading() {
        return this.$store.getters.loading;
      },
      user() {
        return this.$store.getters['user/getUser'];
      },
      manufacturers() {
        return this.$store.getters['manufacturers/getManufacturers'];
      },
      ships() {
        return this.$store.getters['ships/getShipList'];
      },
      shipFilterStore() {
        return this.$store.getters['ships/getShipFilterStore'];
      },
      shoppingCardStore() {
        return this.$store.getters['ships/getShoppingCardStore'];
      },
      /**
       * get drawer with
       * @return {array}
       */
      drawerWidth() {
        let width = '';
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': width = '100%'; break;
          case 'sm': width = '80%'; break;
          case 'md': width = '40%'; break;
          case 'lg': width = '25%'; break;
          case 'xl': width = '25%';
        }
        return width;
      }
    },

    watch: {
      'newShip.hangarShipId': function name(hangarShipId) {
        this.newShip.index = this.newShip.data.findIndex((item) => item.hangarShipId === hangarShipId);
      },
      'editShip.shipId': function name(shipId) {
        this.editShip.index = this.editShip.data.findIndex((item) => item.shipId === shipId);
      },
      page() {
        this.setPages(this.filteredShips);
      },
      ships(ships) {
        if (!ships.length) {return;}

        // set pages
        this.filteredShips = ships;
        this.setPages(this.filteredShips);

        // set size
        this.setShipSize(ships);

        // set name
        this.filteredShipIds = this.ships;

        // set filter
        this.shipFilter = this.shipFilterStore;

        // set shopping card from store
        this.shoppingCard = this.shoppingCardStore;
      },
      manufacturers(manufacturers) {
        // set manu
        this.filteredManufacturers = manufacturers;
      },
      shipName(name) {
        if (!name) {
          this.filteredShipIds = this.ships;
          return;
        }
        this.filteredShipIds = this.ships.filter((item) => item.name.toLowerCase().includes(name.toLowerCase()));
      },
      manufacturerName(name) {
        if (!name) {
          this.filteredManufacturers = this.manufacturers;
          return;
        }
        this.filteredManufacturers = this.manufacturers.filter((item) => item.manufacturerName.toLowerCase().includes(name.toLowerCase()));
      },

      shipFilter: {
        deep: true,
        handler(filter) {
          this.$store.dispatch('ships/setShipFilterStore', filter);
          this.setFilter();
          this.setFilterIconColor();
        }
      },
      checkoutDialog(dialog) {
        if (!dialog) {
          this.shippingCard = [];
          return;
        }
        this.shippingCard = this.shippingCard();
        this.newShip.hangarShipId = this.newShip.data[0].hangarShipId;
      },
      shipPropsDialog(dialog) {
        if (!dialog) {
          this.newShip = {
            index: 0,
            hangarShipId: '',
            data: []
          }
          return;
        }
        const shipsData = [];
        const user = this.$store.getters['user/getUser'];
        for (let i = 0; i < this.shoppingCard.length; i++) {
          for (let o = 0; o < this.shoppingCard[i].quantity; o++) {
            shipsData.push({
              userId: user.userId,
              userName: user.name,
              shipId: this.shoppingCard[i].shipId,
              name: this.shoppingCard[i].name,
              hangarShipId: nanoid(),
              images: {
                small: '',
                big: ''
              },
              shipName: this.shoppingCard[i].shipName || '',
              price: '',
              lti: this.shoppingCard[i].lti || false,
              warbound: this.shoppingCard[i].warbound || false,
              flagship: false
            });
          }
        }
        this.newShip.data = shipsData;
        this.newShip.hangarShipId = shipsData[0].hangarShipId;
      },
      shipsImportDialog(shipsImportDialog) {
        if (!shipsImportDialog) {
          this.importData = '';
        }
      },
      descending() {
        this.setPages(this.filteredShips);
      },
      async shipUpdatePropsDialog(open) {
        try {
          if (open) {
            this.shipPropsDialogLoading = true;
            const ships = await this.$store.dispatch('ships/getShips', this.bulkList);
            const shipDataToEdit = [];
            for (let i = 0; i < this.bulkList.length; i++) {
              const shipData = ships.find((item) => item.shipId === this.bulkList[i]);
              const noRefShip = JSON.parse(JSON.stringify(shipData));
              shipDataToEdit.push({
                shipId: noRefShip.shipId,
                displayName: noRefShip.displayName,
                name: noRefShip.name,
                description: noRefShip.description,
                url: noRefShip.url,
                cargocapacity: noRefShip.cargocapacity,
                price: noRefShip.price
              });
            }
            this.editShip.data = shipDataToEdit;
            this.editShip.shipId = this.bulkList[0];
            this.shipPropsDialogLoading = false;
            return;
          }
          this.editShip = {
            index: 0,
            shipId: [],
            data: []
          }
        } catch (error) {
           this.editShip = {
            index: 0,
            shipId: [],
            data: []
          }
          this.shipPropsDialogLoading = false;
        }
      },
      bulkList(bulkList) {
        if (!bulkList.length) {
          this.bulkActions = false;
        }
      }
    },
    beforeMount() {
      this.loadItems();
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    },

    methods: {
      async loadItems() {
        this.$store.dispatch('loading', true, {root: true});
        if (!this.user.userId) {
          await this.$store.dispatch('user/getUser', this.$auth && this.$auth.user.sub);
        }
        await this.$store.dispatch('manufacturers/getManufacturers');
        await this.$store.dispatch('ships/getShipList');
        this.$store.dispatch('loading', false, {root: true});
      },
      setPages(ships) {
        this.pages = Math.ceil(ships.length / this.maxVisibleItems);
        const offset = (this.page - 1) * this.maxVisibleItems;
        const limit = this.maxVisibleItems;
        const sortedShips = this.sortShips(JSON.parse(JSON.stringify(ships)));
        this.filteredShipsWithPages = sortedShips.slice(offset, offset + limit);
      },
      setShipSize(ships) {
        const shipSizes = [];
        for (let i = 0; i < ships.length; i++) {
          if (!shipSizes.includes(ships[i].size) && ships[i].size !== 'N/A') {
            shipSizes.push(ships[i].size);
          }
        }
        this.shipSizes = shipSizes;
      },
      setFilter() {
        let ships = this.ships;
        for (const key in this.shipFilter) {
          if (Object.hasOwnProperty.call(this.shipFilter, key)) {
            if (key === 'shipName' && this.shipFilter[key]) {
              ships = ships.filter((item) => item.name.toLowerCase().includes(this.shipFilter[key].toLowerCase()));
            }
            if (key === 'shipIds' && this.shipFilter[key].length) {
              ships = ships.filter((item) => this.shipFilter[key].includes(item.shipId));
              this.filteredManufacturers = this.manufacturers.filter((manufacturer) => {
                const hasShip = ships.find((ship) => ship.manufacturerId === manufacturer.manufacturerId);
                if (hasShip) {
                  return true;
                }
                return false;
              });
            }
            if (key === 'shipIds' && !this.shipFilter[key].length) {
              this.filteredManufacturers = this.manufacturers;
            }

            if (key === 'manufacturerIds' && this.shipFilter[key].length) {
              ships = ships.filter((item) => this.shipFilter[key].includes(item.manufacturerId));
               this.filteredShipIds = this.ships.filter((ship) => {
                const hasManufacturer = ships.find((manufacturer) => manufacturer.manufacturerId === ship.manufacturerId);
                if (hasManufacturer) {
                  return true;
                }
                return false;
              });
            }
            if (key === 'manufacturerIds' && !this.shipFilter[key].length) {
              this.filteredShipIds = this.ships;
            }
            if (key === 'size' && this.shipFilter[key].length) {
              ships = ships.filter((item) => this.shipFilter[key].includes(item.size));
            }
          }
        }
        this.setShipSize(ships);
        this.filteredShips = ships;
        if (this.page !== 1) {
          this.page = 1;
          return;
        }
        this.setPages(this.filteredShips);
      },
      setFilterIconColor() {
        let color = 'gray';
        for (const key in this.shipFilter) {
          if (Object.hasOwnProperty.call(this.shipFilter, key)) {
            if (key !== 'shipName' && this.shipFilter[key].length) {
              color = 'red';
            }
          }
        }
        this.filterIconColor = color;
      },
      addShipToShoppingCard(data) {
        const shipIndex = this.shoppingCard.findIndex((item) => item.shipId === data.shipId);
        if (shipIndex === -1) {
          this.shoppingCard.push(data);
          this.$store.dispatch('ships/setShoppingCardStore', this.shoppingCard);
          return;
        }
        if (this.shoppingCard[shipIndex].quantity === 10) {
          return;
        }
        ++this.shoppingCard[shipIndex].quantity;
        this.$store.dispatch('ships/setShoppingCardStore', this.shoppingCard);
      },
      removeShipFromShoppingCard(shipId) {
        this.shoppingCard = this.shoppingCard.filter((item) => item.shipId !== shipId);
        if (!this.shoppingCard.length) {
          this.shoppingCardDialog = false;
        }
        this.$store.dispatch('ships/setShoppingCardStore', this.shoppingCard);
      },
      addShipToBulkList(shipId) {
        if (!this.bulkList.includes(shipId)) {
          this.bulkList.push(shipId);
          return;
        }
        this.bulkList = this.bulkList.filter((item) => item !== shipId);
      },
      async addShipsToHangar() {
        this.addShipsToHangarLoading = true;
        await this.$store.dispatch('hangar/addShips', this.newShip.data);
        this.addShipsToHangarLoading = false;
        this.shipPropsDialog = false;
        this.shoppingCard = [];
        this.$store.dispatch('ships/setShoppingCardStore', []);
        this.$swal({
          toast: true,
          position: 'top-end',
          title: 'success',
          timer: 3000,
          icon: 'success',
          showConfirmButton: false
        });
      },
      async importShips() {
        let json = null;
        try {
          json = JSON.parse(this.importData);
        } catch (error) {
          this.$swal({
            text: 'Ungültiges JSON Format',
            icon: 'error',
            showConfirmButton: true
          });
          return;
        }
        let cantFindShips = '';

        for (let o = 0; o < json.length; o++) {
          const foundShip = this.ships.find((item) => item.name === json[o].name);
          if (foundShip && foundShip.shipId) {
            this.addShipToShoppingCard({
              shipId: foundShip.shipId,
              name: foundShip.name,
              shipName: json[o].ship_name || '',
              price: '',
              lti: json[o].lti || false,
              warbound: json[o].warbound || false,
              quantity: 1
            });
          } else {
            cantFindShips = cantFindShips ? cantFindShips + ', ' + json[o].ship_name : cantFindShips = json[o].ship_name;
          }
        }

        this.shipsImportDialog = false;

        if (!cantFindShips) {
          this.$swal({
            toast: true,
            position: 'top-end',
            text: 'Es wurde alle Schiffe gefunden und in deinen Warenkorb gelegt',
            icon: 'success',
            showConfirmButton: true
          });
          return;
        }
        this.$swal({
          html: `<div style="text-align: left;">Es wurden nicht alle Schiffe gefunden, diese Schiffe "${cantFindShips}" müssen manuell hinzugefügt werden, der rest wurde in den Warenkorb gelegt</div>`,
          icon: 'info',
          showConfirmButton: true
        });
      },
      sortShips(ships) {
        const sortedShips = ships;
        if (this.descending) {
          sortedShips.sort(function (a, b) {
            if (a.name > b.name) {
                return -1;
            }
            if (b.name > a.name) {
                return 1;
            }
            return 0;
          });
        } else {
          sortedShips.sort(function (a, b) {
            if (a.name > b.name) {
                return 1;
            }
            if (b.name > a.name) {
                return -1;
            }
            return 0;
          });
        }
        return sortedShips;
      },
      async updateShips() {
        this.$swal({
          title: 'Markierte Schiffe updaten?',
          icon: 'warning',
          showCancelButton: true,
          showLoaderOnConfirm: true,
          cancelButtonText: 'Nein',
          confirmButtonText: 'Ja',
          preConfirm: async () => {
            try {
              await this.$store.dispatch('ships/updateShips', this.editShip.data);
              this.shipUpdatePropsDialog = false;
              this.$swal({
                toast: true,
                timer: 3000,
                position: 'top-end',
                title: 'success',
                icon: 'success',
                showConfirmButton: false
              });
            } catch (error) {
              // NOOP
            }
          }
        });
      },
      async resetShipData() {
        this.$swal({
          title: 'Daten der Markierten Schiffe resetten?',
          icon: 'warning',
          showCancelButton: true,
          showLoaderOnConfirm: true,
          cancelButtonText: 'Nein',
          confirmButtonText: 'Ja',
          preConfirm: async () => {
            try {
              await this.$store.dispatch('ships/resetShipsData', this.bulkList);
              this.shipUpdatePropsDialog = false;
              this.$swal({
                toast: true,
                timer: 3000,
                position: 'top-end',
                title: 'success',
                icon: 'success',
                showConfirmButton: false
              });
            } catch (error) {
              // NOOP
            }
          }
        });
      },
      setBulkActions() {
        if (this.user.rank === 'Wildest One') {
          this.bulkActions = true;
        }
      },
      resetFilter() {
        this.$store.dispatch('hangar/resetShipFilterStore');
        this.shipFilter = {
          shipName: '',
          shipIds: [],
          manufacturerIds: [],
          size: []
        };
        this.setFilterIconColor();
        this.filterDrawer = false;
      }
    }
  };
</script>

<style scoped>
  .custom-toolbar {
    position: sticky;
    top: 0;
    z-index: 100;
  }
</style>
